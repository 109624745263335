import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[capitalize]',
  host: {
    '[value]': 'text',
    '(input)': 'format($event.target.value)'
  },
  standalone: false
})
export class CapitalizeDirective implements OnInit {

  @Input('capitalize') private text: string;

  public constructor(private model: NgControl) {
  }

  public ngOnInit(): void {
    this.text = this.text || '';
    setTimeout((): void => {
      this.format(this.text);
    }, 0);
  }

  public format(value: string): void {
    const newValue = value.toLowerCase().replace(/\w/, replacer => replacer.toUpperCase());
    this.model.control.setValue(newValue.trim());
    this.model.valueAccessor.writeValue(newValue);
  }

}
