import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-list-select-expandable',
  templateUrl: './list-select-expandable.component.html',
  styleUrls: ['./list-select-expandable.component.scss'],
  standalone: false
})
export class ListSelectExpandableComponent implements OnInit {

  @Input() public title: string;
  @Input() public description: string;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public platform: string;
  @Input() public hidden: boolean;
  public selected = false;

  public constructor() {
  }

  public ngOnInit(): void {
    this.formGroup.disable();
  }

  public handleSelected(): void {
    this.selected = true;
    this.formGroup.enable();
  }

  public handleDeselected(): void {
    this.selected = false;
    this.formGroup.disable();
  }

}
