import { catchError, shareReplay, switchMap, take } from 'rxjs/operators';
import { defer, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export function catchAndUnsubscribe(): (Obs) => Observable<any> {
  return (obs): Observable<any> => obs.pipe(
    take(1),
    catchError((err): Observable<never> => {
      return throwError(() => err);
    })
  )
}

export function reCaptchaAction(action: string, request: (tok: string) => Observable<any>): Observable<any> {
  if (!window['grecaptcha']) {
    return new Observable(
      (sub): void => {
        setTimeout((): void => {
          reCaptchaAction(action, request)
            .subscribe((res): void => {
              sub.next(res);
              sub.complete()
            })
        }, 500)
      }
    )
  }
  const grecaptchaExecute =
    (siteKey, action): Observable<any> => new Observable((sub): void => {
      window['grecaptcha'].ready((): void => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        window['grecaptcha'].execute(siteKey, action).then((res): void => {
          sub.next(res);
          sub.complete();
        })
      })
    })

  return defer((): Observable<any> => grecaptchaExecute('6LdDHrUUAAAAAKzMz2EpUXQFj554t_g0Gu8BJetN', { action }))
    .pipe(
      switchMap((token: string): Observable<any> => {
        return request(token);
      }),
      catchAndUnsubscribe(),
    )
}

export function getAPI(http: HttpClient, route: string, params?: { [key: string]: string }): Observable<any> {
  return http.get(`${environment.apiUrl}${route}`, { params }).pipe(catchAndUnsubscribe(), shareReplay());
}

export function postAPI(http: HttpClient, route: string, body: any, params?: { [key: string]: any }): Observable<any> {
  return http.post(`${environment.apiUrl}${route}`, body, params).pipe(catchAndUnsubscribe(), shareReplay());
}
