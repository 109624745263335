import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  standalone: false
})
export class FormSectionComponent implements OnInit {

  @ContentChild('deactivateSwitch') deactivateSwitchTemplate: TemplateRef<any>;
  @ContentChild('replaceContent') replaceContentTemplate: TemplateRef<any>;
  @Input() title: string;
  @Output() onDisabled = new EventEmitter<any>();


  toggle: UntypedFormControl = new UntypedFormControl(false);

  constructor() {
  }

  ngOnInit(): void {
    this.toggle.valueChanges.subscribe((value) => this.onDisabled.emit(value));
  }

}
