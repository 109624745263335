import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[pricingCardInput]',
  standalone: false
})
export class PricingCardInputDirective {

  public name: string;
  public label: string;
  public value: string;
  public type: string;
  public info: string;

  constructor(el: ElementRef) {
    this.name = el.nativeElement.name;
    this.label = el.nativeElement.placeholder;
    this.value = el.nativeElement.value;
    this.type = el.nativeElement.type;
    this.info = (el.nativeElement.attributes.info || {}).value;
  }

}
