import { Directive, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appIBAN]',
  host: {
    '[value]': 'number',
    '(input)': 'format($event.target.value)'
  },
  standalone: false
})
export class IBANFormatDirective implements OnInit {

  @Input('appIBAN') private number: string;

  public constructor(private model: NgControl) {
  }

  public ngOnInit(): void {
    this.number = this.number || '';
    setTimeout((): void => {
      this.format(this.number);
    }, 0);
  }

  public format(value: string): void {
    if (value) {
      value = value.replace(/[^a-zA-Z0-9]+/g, '');
      const sanitisedValue = value.substr(0, 2).replace(/[^a-zA-Z]+/g, '') + value.substr(2, 2).replace(/[^0-9]+/g, '') + value.substr(4, 4).replace(/[^a-zA-Z0-9]+/g, '') + value.substr(8).replace(/[^0-9]+/g, '');
      let formatedValue = '';
      for (let index = 0; index <= Math.ceil(sanitisedValue.length / 4); index++) {
        formatedValue += sanitisedValue.substr(index * 4, 4);
        if (index < Math.ceil(sanitisedValue.length / 4) - 1) {
          formatedValue += ' ';
        }
      }
      this.model.control.setValue(sanitisedValue);
      this.model.valueAccessor.writeValue(formatedValue.toUpperCase());
    }
  }
}

@Directive({
  selector: '[appVAT]',
  host: {
    '[value]': 'number',
    '(input)': 'format($event.target.value)'
  },
  standalone: false
})
export class VATFormatDirective implements OnInit {

  @Input('appIBAN') private number: string;

  public constructor(private model: NgControl) {
  }

  public ngOnInit(): void {
    this.number = this.number || '';
    setTimeout((): void => {
      this.format(this.number);
    }, 0);
  }

  public format(value: string): void {
    if (value) {
      value = value.replace(/[^a-zA-Z0-9]+/g, '').toUpperCase();
      this.model.control.setValue(value);
      this.model.valueAccessor.writeValue(value);
    }
  }
}


@Directive({
  selector: '[appCurrency]',
  host: {
    '[value]': 'number',
    '(input)': 'format($event.target.value)'
  },
  standalone: false
})
export class CurrencyFormatDirective implements OnInit {

  @Input('appCurrency') private number: string;
  private previousValue = '';

  public constructor(private model: NgControl) {
  }

  public ngOnInit(): void {
    this.number = this.number || '';
    setTimeout((): void => {
      this.format(this.number);
    }, 0);
  }

  public format(value: string): void {
    if (value) {
      const splitValue = value.replace(/[^0-9\.,]+/g, '').split(/[\.,]/g);
      const sanitisedValue = splitValue[0] + (typeof splitValue[1] == 'string' ? ',' + splitValue[1] : '');
      this.previousValue = sanitisedValue;
      this.model.control.setValue(parseFloat(sanitisedValue.replace(',', '.')));
      this.model.valueAccessor.writeValue('€' + sanitisedValue);
      return
    }

    this.model.control.setValue(null);
    this.model.valueAccessor.writeValue('€');
  }
}

@Directive({
  selector: '[appBIC]',
  host: {
    '[value]': 'number',
    '(input)': 'format($event.target.value)'
  },
  standalone: false
})
export class BICFormatDirective implements OnInit {

  @Input('appBIC') private number: string;
  private previousValue = '';

  public constructor(private model: NgControl) {
  }

  public ngOnInit(): void {
    this.number = this.number || '';
    setTimeout((): void => {
      this.format(this.number);
    }, 0);
  }

  public format(value: string): void {
    if (value) {
      if (value.length > 11) {
        value = this.previousValue;
      }

      const sanitisedValue = value.replace(/[^a-zA-Z0-9]+/g, '');
      const formatedValue =
        sanitisedValue.substr(0, 6).replace(/[^a-zA-Z]+/g, '') +
        sanitisedValue.substr(6, 5);

      if (formatedValue != sanitisedValue) {
        this.model.control.setValue(this.previousValue);
        this.model.valueAccessor.writeValue(this.previousValue);
        return
      }

      this.model.control.setValue(formatedValue);
      this.model.valueAccessor.writeValue(formatedValue.toUpperCase());
      this.previousValue = formatedValue;
    }
  }
}
