import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
  QueryList
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PricingCardInputDirective } from './pricing-card-input.directive';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StylisedSnackbarComponent } from '../stylised-snackbar/stylised-snackbar.component';

@Component({
  selector: 'app-pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.scss'],
  standalone: false
})
export class PricingCardComponent implements AfterContentInit {

  @ContentChildren(PricingCardInputDirective) public inputs: QueryList<any>;
  @Input() public cardTitle: string;
  @Input() public subtitle: string;
  @Input() public platform: string;
  @Input() public values: any;
  public platformUppercase: string;
  public formControls: UntypedFormGroup;
  public todayDate: number = Date.now();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Output() private valueChanges: EventEmitter<any> = new EventEmitter();

  public constructor(
    private _dialog: MatDialog,
    private _translate: TranslateService,
    @Inject(LOCALE_ID) public locale: string
  ) {
  }

  public ngAfterContentInit(): void {

    this.platformUppercase = this.platform.toUpperCase();

    this.formControls = new UntypedFormGroup({});

    this.inputs.forEach((input): void => {
      this.formControls.addControl(input.name as string, new UntypedFormControl(input.value || '0'));
    })

    this.formControls.valueChanges.pipe(debounceTime(1000)).subscribe((): void => {
      setTimeout((): void => {
        const values = Object.keys(this.formControls.value as object).reduce((acc, key): {} => ({
          ...acc,
          [key]: parseInt(this.formControls.value[key] as string || '0')
        }), {});
        this.valueChanges.emit({ id: this.platform, value: values });
      }, 0);
    })
  }

  public tooltipDialog(message): void {
    this._dialog.open(StylisedSnackbarComponent, {
      maxWidth: '400px',
      data: {
        platform: this.platformUppercase,
        message: message
      }
    });
  }

  public isEnabled(value): boolean {
    return !!(value != '0,00');
  }

}
