import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  RsSelectableCardExtraComponent
} from '../../card-select-input/rs-selectable-card-extra/rs-selectable-card-extra.component';

@Component({
  selector: 'app-rs-selectable-card',
  templateUrl: './rs-selectable-card.component.html',
  styleUrls: ['./rs-selectable-card.component.scss'],
  standalone: false
})
export class RsSelectableCardComponent implements AfterContentInit, OnChanges {

  @Input() public value: string;
  @Input() public title: string;
  @Input() public icon: string;
  @Input() public checkbox: boolean = false;
  @Input() public customIcon: boolean = false;
  @Input() public description: string;
  @Input() public customClass: string;
  @Input() public disabled: boolean = false;
  @ContentChildren(RsSelectableCardExtraComponent) public extraFields: QueryList<RsSelectableCardExtraComponent>;
  public selected: boolean = false;
  public optional: any;
  public enumKeys = Object.keys;
  public optionalControls: { [keys: string]: UntypedFormControl } = {};

  constructor(private el: ElementRef) {
  }

  public get state(): { value: string; selected: boolean; } {
    const {
      value, selected
    } = this;
    return { value, selected }
  }

  public ngAfterContentInit(): void {

    if (this.extraFields.length) {
      this.value += '?'
      this.optionalControls = this.extraFields.reduce((acc, opt) => {

        acc[opt.name] = new UntypedFormControl('', opt.getValidators());
        this.value += opt.name + '=' + '&'
        acc[opt.name].valueChanges.subscribe((val) => {
          let value;
          if (!acc[opt.name].valid) {
            value = this.value.replace(new RegExp(opt.name + '=.*&', 'g'), opt.name + '=&');
          } else {
            const input = val.trim()
            value = this.value.replace(new RegExp(opt.name + '=.*&', 'g'), opt.name + '=' + input + '&');
          }
          if (this.value != value) {
            this.value = value;
            this.handleClickCallback(this.state);
          }
        });
        return acc;
      }, {})
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && changes.disabled.currentValue) {
      this.setState(true);
      this.handleClickCallback(this.state);
    }
  }

  public onClick(cb): void {
    this.handleClickCallback = cb;
  }

  public handleClick(): void {
    if (!this.disabled) {
      this.handleClickCallback(this.state);
      if (!this.extraFields.length) {
        return;
      }

      const input = this.el.nativeElement.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }

  public setState(selected: boolean): void {
    this.selected = selected;
  }

  private handleClickCallback: (x?: any) => void = () => {
  };

}
