import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  standalone: false
})
export class FormErrorComponent {

  @Input() parentForm: UntypedFormGroup;
  @Input() path: string;
  @Input() error: any;

  public getMessage(): string {
    const field = this.parentForm.get(this.path);
    if (field.pristine == true) {
      return '';
    }

    return Object.keys(field && field.errors || {}).map(err => err == 'required' && '' || this.error[err] || '').join('<br/>');
  }

}
