/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import {
  AfterViewInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  QueryList
} from '@angular/core';
import { RsSelectableCardComponent } from '../rs-selectable-card/rs-selectable-card.component';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-rs-card-select',
  templateUrl: './rs-card-select.component.html',
  styleUrls: ['./rs-card-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RsCardSelectComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useValue: (c: UntypedFormControl) => {
        if (typeof c.value != 'string') {
          return !c.value.length ? { required: { required: true } } : null;
        }
        const err = {
          optional: {
            value: c.value,
            required: true
          }
        };
        return c.value && (new RegExp(/\?/, 'g').test(c.value) && !new RegExp(/\?.*=.+&/, 'g').test(c.value)) ? err : null;
      },
      multi: true,
    },
  ],
  standalone: false
})

export class RsCardSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnChanges {

  @Input() multiple: boolean = false;
  @ContentChildren(RsSelectableCardComponent) cards: QueryList<RsSelectableCardComponent>;

  constructor() {
  }

  @Input('value') _value;

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.propagateChange(val);
  }

  static cardIsSelected(values, val): string {
    return values.find((searchedVal) => searchedVal == val);
  }

  propagateChange: any = () => {
  };

  validateFn: any = () => {
  };

  public ngOnInit() {
    if (!this.multiple) {
      this.value = ''
    } else {
      this.value = []
    }
  }

  public ngAfterViewInit() {

    setTimeout(() => {
      if (!this.multiple) {
        this.cards.filter((c) => c.value == this.value).forEach((c) => c.selected = true);
      } else {
        this.cards.forEach((c) => c.selected = this.value.find((v) => v == c.value));
      }
    })

    this.cards.forEach((card) => {
      card.onClick((_data) => {
        this.handleCardClick(card);
      })
    });
  }

  public ngOnChanges() {
    // Not really needed , only if you want to change multiple input dynamically
  }

  public writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  public registerOnChange(fn) {
    this.propagateChange = fn;
  }

  public validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }

  public registerOnTouched(): void {
  }

  private handleCardClick(data) {
    if (!this.multiple) {
      this.value = data.value;
      this.cards.forEach((c) => c.selected = false);
      data.selected = true;
    } else {
      data.selected = !data.selected;
      if (!data.selected) {
        this.value = this.value.filter((v) => v !== data.value);
      } else {
        this.value = this.value.concat([data.value]);
      }
    }
  }

}
