import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubheaderMenuItems } from '@lib/rs-ngx';
import { filter, startWith } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';
import * as appActions from '../../reducers/app/app.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { AccountService } from '../../services/account/account.service';

@Component({
  selector: 'app-phase-two',
  templateUrl: './phase-two.component.html',
  styleUrls: ['./phase-two.component.scss'],
  standalone: false
})
export class PhaseTwoComponent implements OnInit {

  public router: Router;
  public topBarEnvironment = environment.topBarEnvironment;
  public topBarCdn = environment.topBarCdn;
  public subheaderMenu: SubheaderMenuItems[] = [
    {
      name: 'PRICING',
      path: 'portal/pricing'
    },
    {
      name: 'PLATFORMS',
      path: 'portal/platforms'
    }
  ];
  public headerTitle = 'PRICING_SIMULATOR';
  public fullscreen = false;
  private subheaderTitles: string[] = ['PRICING_SIMULATOR', 'PLATFORMS'];

  public constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private accountService: AccountService,
    private store: Store<AppState>
  ) {
  }

  public ngOnInit(): void {
    this.router = this._router;

    this._router.events.pipe(
      startWith(this.router),
      filter((event): boolean => event instanceof NavigationEnd || event instanceof Router)
    ).subscribe((): void => {
      const selected = (this.route.snapshot.firstChild || { data: { selected: null } }).data.selected;
      this.fullscreen = !!(this.route.snapshot.firstChild || { data: { fullscreen: null } }).data.fullscreen;

      if (typeof selected != 'undefined') {
        this.subheaderMenu.forEach((item, index): void => {
          if (index == selected) {
            this.headerTitle = this.subheaderTitles[index];
            item.selected = true;
            return;
          }
          item.selected = false;
        });
      }
    });

    /** Store conditions documents */
    forkJoin(
      this.accountService.getConditions('FR'),
      this.accountService.getConditions('NL'),
      this.accountService.getConditions('EN'),
      this.accountService.getConditions('DE')
    )
      .subscribe((conditionDocuments): void => {
        this.store.dispatch(new appActions.SetDocuments({
          fr: conditionDocuments[0],
          nl: conditionDocuments[1],
          en: conditionDocuments[2],
          de: conditionDocuments[3]
        }));
      });
  }

}
